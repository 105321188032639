<template>
  <div>
    <div class="pc">
      <div class="additional-service-container">
        <div class="banner">
          <div class="container grid-banner">
            <div class="circle"></div>
            <div style="z-index:1">
              <div class="subtitle6 success">부가서비스</div>
              <div class="h6 main margin-top-16">런칭 준비가 끝나셨나요?<br>
                부가서비스로, 내 서비스를 본격적으로 사업화할 수 있습니다.</div>
              <div class="body2 sub2 margin-top-20">앱 포팅 및 앱마켓 등록 대행, GA(Google Analytics) 등록 등<br>
                비즈니스 운영에 필요한, 여러 가지 부가서비스등을 활용해<br>
                서비스를 본격적으로 사업화할 수 있습니다.</div>
            </div>
          </div>
        </div>
        <div class="margin-top-60">
          <div class="container">
            <div class="h7 main margin-bottom-20">부가서비스</div>
            <div class="grid-cards">
              <card-product v-for="card in list" :key="`card-${card.id}`" :card="card" isAddService rate="1:1"></card-product>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="additional-service-container">
        <div class="banner">
          <div class="position-relative" style="z-index:1">
            <div class="subtitle7 success">부가서비스</div>
            <div class="mo-section-title-bold main margin-top-8">런칭 준비가 끝나셨나요?<br>
            부가서비스로<br>내 서비스를 본격적으로<br>사업화할 수 있습니다.</div>
            <div class="body4 sub2 margin-top-20">앱 포팅 및 앱마켓 등록 대행,<br>GA(Google Analytics) 등록 등<br>
              비즈니스 운영에 필요한<br>여러 가지 부가서비스등을 활용해<br>
              서비스를 본격적으로 사업화할 수 있습니다.</div>
          </div>
        </div>
        <div class="h8 main" style="padding:24px 16px 12px 16px">부가서비스</div>

        <div v-for="(card, idx) in list" :key="`card-${card.id}`">
          <div class="gap" v-if="idx>0"></div>
          <card-product :card="card" style="padding:16px" isAddService rate="1:1"></card-product>
        </div>
        <div style="padding:16px" v-if="page_num < total_page">
          <button class="button is-gray button-more" @click="clickMore">더보기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardProduct from "../component/CardProduct";
  export default {
    name: "AdditionalService",
    components: {CardProduct},
    created() {
      this.getData();
    },
    data() {
      return {
        list: [],
        page_num: 1,
        total_page: 1,
      }
    },
    methods: {
      clickMore() {
        this.page_num++;
        this.getData();
      },
      getData() {
        let url = 'user/0/mapping/product';
        let params = {
          fields: 'id,name,simple_desc,img,price,partner.img,partner.name,category1,category2',
          page_num: this.page_num,
          page_length: 12,
          category:64
        };
        this.$axios.get(url, {params: params}).then(res => {
          this.list = res.data.data;
          this.total_page = res.data.total_page;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .pc
    .additional-service-container
      padding 64px 0 80px 0
    .banner
      background-color $gray4
      padding 94px 0
      overflow hidden
    .grid-banner
      position relative
      display grid
      gap 60px
      grid-template-columns 1fr 502px

    .circle
      position absolute
      width 783px
      height 783px
      left 673px
      bottom -292px
      background #17FF90
      opacity 0.3
      filter blur(410px)
      z-index 0

    .grid-cards
      display grid
      grid-column-gap 36px
      grid-row-gap 48px
      grid-template-columns repeat(3, 1fr)


  .mobile
    .additional-service-container
      padding 59px 0 80px 0
    .banner
      background-color #EDFBF4
      padding 40px 0
      overflow hidden
      position relative
      text-align center

    .grid-banner
      position relative
      display grid
      gap 60px
      grid-template-columns 1fr 502px

    .circle
      position absolute
      width 356px
      height 356px
      left calc(50% - 80px)
      top calc(50% - 38px)
      background #17ff90
      opacity 0.5
      filter blur(200px)
      z-index 0


</style>
